import React, { useState } from 'react';
import './Contact.css';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

function Contact() {
  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    number: '',
    message: ''
  });

  // State for form submission status
  const [status, setStatus] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          access_key: '9ceb1c14-fe18-497d-80db-81191c964242'
        })
      });

      if (response.ok) {
        Swal.fire({
          title: "Message Sent!",
          text: "Successfully",
          icon: "success"
        });

        setFormData({
          name: '',
          email: '',
          subject: '',
          number: '',
          message: ''
        });
      } else {
        setStatus('Error sending message');
      }
    } catch (error) {
      setStatus('Network error');
    }
  };

  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us - Elegant Academy</title>
        <meta name="description" content="Contact Elegant Academy for any questions or further information. Fill out our contact form and we will get back to you as soon as possible." />
        <meta name="keywords" content="contact Elegant Academy, language courses, student inquiries, Kochi language school" />
        <meta property="og:title" content="Contact Us - Elegant Academy" />
        <meta property="og:description" content="Get in touch with Elegant Academy for more information about our courses and services. Fill out the contact form to reach us." />
        <meta property="og:url" content="https://yourwebsite.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
        <meta name="twitter:title" content="Contact Us - Elegant Academy" />
        <meta name="twitter:description" content="Contact Elegant Academy for any questions or further information. Fill out our contact form and we will get back to you as soon as possible." />
        <meta name="twitter:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
      </Helmet>

      <h2>Contact Us</h2>
      <p>If you have any questions or need further information, please don't hesitate to contact us. Fill out the form below and we will get back to you as soon as possible.</p>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Phone Number</label>
          <input
            type="number"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Send Message</button>
      </form>
      {status && <p>{status}</p>}

      <section className="location">
        <h2>Our Location</h2>
        <div className="map-container">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1875506386427!2d76.2984495747936!3d10.001361490104102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa341573b5c27b26d%3A0xe338ac5bd8f2db4a!2sElegant%20Academy%20Kochi!5e0!3m2!1sen!2sin!4v1722487015216!5m2!1sen!2sin"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </section>

      <div className="contact-info">
        <h3>Our Address</h3>
        <p>
          <i className="fas fa-map-marker-alt"></i>
          Elegant Academy Kochi, Metro piller no.549, Banerji Road
        </p>
        <p>
          <i className="fas fa-phone"></i>
          9539411177
        </p>
        <p>
          <i className="fas fa-envelope"></i>
        infoelegantskilltech@gmail.com
        </p>
      </div>

      <footer className="footer">
        <p>&copy; 2024 Elegant Academy. All rights reserved.</p>
        <a href="https://www.freepik.com">Designed by Freepik</a>
        <div className="footer-icons">
          <a href="https://www.instagram.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="gmailto:elegantgerman1@gmail.com">
            <i className="fas fa-envelope"></i>
          </a>
          <a href="https://www.facebook.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
