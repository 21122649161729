// src/components/Loading.js
import React from 'react';
import './Loading.css'; // For styling the loading component
import image1 from '../components/loading.gif';
const Loading = () => {
    return (
        <div className="loading-container">
            <img src={image1} alt="Loading..." />
        </div>
    );
};

export default Loading;
