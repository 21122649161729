import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../components/1logo.avif'; // Adjust the path to your logo image

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo-title">
          <img src={logo} alt="Elegant Academy Logo" className="logo" />
        </div>
        <button className="hamburger" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </button>
        <nav className={`nav ${menuOpen ? 'active' : ''}`}>
          <Link to="/" className="nav-item">Home</Link>
          <Link to="/courses" className="nav-item">Courses</Link>
          <Link to="/contact" className="nav-item">Contact</Link>
          <Link to="/info" className="nav-item">Info</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
