import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../components/GermanLanguage.css'; // Import CSS for styling
import germanImage from '../components/abacus.avif'; // Adjust the path to your image

function Smaabacus() {
  return (
    <div className="course-details">
      <Helmet>
        <title>SMA Abacus Course for Children</title>
        <meta name="description" content="Enroll in our Abacus Mastery Course for Children to develop strong mental arithmetic skills quickly and efficiently. Our engaging 12-week course offers flexible schedules and interactive lessons." />
        <meta name="keywords" content="Abacus course, children abacus training, mental arithmetic, interactive lessons, online abacus course" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/courses/SMA-Course" />
      </Helmet>
      <img src={germanImage} alt="Abacus Course" className="course-image" />
      <div className="course-content">
        <h2>SMA Abacus Course</h2>
        <p>
          <strong>
            Our Abacus Course for Children is designed to help young learners develop strong mental arithmetic skills quickly and efficiently. Whether your child is a beginner or looking to enhance their existing skills, our course offers engaging and comprehensive training to meet their needs.
          </strong>
        </p>
        <h3>Course Highlights:</h3>
        <ul>
          <li>Interactive lessons with experienced instructors</li>
          <li>Focus on mental arithmetic, concentration, and speed</li>
          <li>Fun and engaging activities to keep children motivated</li>
          <li>Flexible schedules to suit your availability</li>
          <li>Access to additional resources and practice materials</li>
        </ul>
    
        <h3>Enroll Now!</h3>
        <p>
          <strong>
            Ready to start your child's journey with the Abacus? Click the button below to enroll or contact us for more details.
          </strong>
        </p>
        <Link to="/contact" className="btn btn-primary">Contact Us</Link>
        <ul className="navigation-links">
          <h3>Explore Other Courses:</h3>
          <li><Link to="/courses/Spoken-English">SPOKEN ENGLISH</Link></li>
          <li><Link to="/courses/German-Language">GERMAN LANGUAGE</Link></li>
          <li><Link to="/courses/IELTS">IELTS</Link></li>
          <li><Link to="/courses/Tuition">TUTION</Link></li>
        </ul>
      </div>
    </div>

       
  );
}

export default Smaabacus;

