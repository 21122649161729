import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Home from './components/Home';
import Courses from './components/Courses';
import Contact from './components/contact';
import Info from './components/Info';
import GermanLanguage from './components/GermanLanguage';
import SpokenEnglish from './components/SpokenEnglish';
import IELTS from './components/IELTS';
import Tuition from './components/Tution';
import SMAAbacus from './components/SMAAbacus';
import ScrollToTop from './components/ScrollToTop';
import '@fortawesome/fontawesome-free/css/all.min.css';
import WhatsAppButton from './components/WhatsAppButton';
import CallUsButton from './components/CallUsButton';
import Loading from './components/loading'; // Import the Loading component
import CoverPage from './components/CoverPage'; // Import the CoverPage component
import './App.css';
import './components/Header.css';
import './components/Home.css';
import './components/Courses.css';
import './components/Contact.css';
import './components/Info.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or replace this with your actual loading logic
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds delay for demonstration
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <ScrollToTop /> {/* Add this line to enable scroll to top on route change */}
      <div>
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<CoverPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/info" element={<Info />} />
            <Route path="/courses/german-language" element={<GermanLanguage />} />
            <Route path="/courses/spoken-english" element={<SpokenEnglish />} />
            <Route path="/courses/ielts" element={<IELTS />} />
            <Route path="/courses/tuition" element={<Tuition />} />
            <Route path="/courses/sma-abacus" element={<SMAAbacus />} />
          </Routes>
        </div>
        <WhatsAppButton />
        <CallUsButton />
      </div>
    </Router>
  );
}

export default App;
