import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import './Home.css';
import Statistics from './Statistics';
import { Helmet } from 'react-helmet';
import image4 from '../components/spoken.avif';
import image5 from '../components/tution_1.avif';
import image6 from '../components/learngerman_1.avif';
import image1 from '../components/abacus.avif';
import image7 from '../components/antonyss.png';
import image8 from '../components/amrithass.png';
import image9 from '../components/davidossss.png';
import staticBackground from '../components/dbback.avif'
import FeaturedCourse from './FeaturedCourse';



const Home = () => (
  <div className="home">
    <Helmet>
      <title>Welcome to Elegant Academy | Language Courses & Student Experiences</title>
      <meta name="description" content="Elegant Academy offers a dynamic and immersive language learning experience. Discover our courses, read student testimonials, and find our location." />
      <meta name="keywords" content="language academy, language courses, student experiences, Elegant Academy, language learning" />
    </Helmet>

    

    <section className="hero">
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={image1} alt="Elegant Academy - Welcome" loading="lazy" />
          <Carousel.Caption>
            <h2>Enhance Arthemetical skills with SMA Abacus</h2>
            <div>
              <Link to="/courses/sma-abacus" className="btn btn-primary mr-2">SMA Abacus</Link>
              <Link to="/info" className="btn btn-primary">ABOUT</Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={image6} alt="Student Success Stories" loading="lazy" />
          <Carousel.Caption>
          
            <div>
              <Link to="/courses/German-Language" className="btn btn-primary mr-2">GERMAN</Link>
              <Link to="/info" className="btn btn-primary">ABOUT</Link>
              <h2>Click above to know more </h2>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="hero-content">
        <h1>Welcome to Elegant Academy</h1>
        <p><strong>Welcome to Elegant Academy, where language mastery meets cultural enrichment! We transcend traditional language education by fostering a dynamic and immersive learning experience that goes beyond mere linguistic proficiency. Our mission is to empower individuals with the transformative ability to communicate seamlessly in a globalized world.</strong></p>
        <h2>Our Philosophy</h2>
        <p>At Elegant Academy, we understand that language acquisition is both an art and a science. Our innovative curriculum blends time-tested methodologies with the latest advancements in language pedagogy. We are dedicated to providing an engaging and holistic language learning experience.</p>
        <h2>Our Approach</h2>
        <ul>
          <li>Interactive Classroom Sessions: Our classes are designed to encourage active participation, ensuring that students are constantly engaged and involved in their learning journey</li>
          <li>Real-World Applications: We bridge the gap between classroom learning and real-world usage, equipping students with the practical skills needed for everyday communication</li>
          <li>Cultural Immersion: We believe that true language mastery involves understanding the cultural context. Our programs include immersive cultural activities that enrich the learning experience.</li>
          <li>Expert Instructors: Our team of passionate and experienced instructors are committed to helping each student achieve their language goals through personalized and effective teaching methods</li>
        </ul>
        <h2>Why Choose Elegant Academy</h2>
        <ul>
          <li>Comprehensive Programs: We offer a variety of programs tailored to meet the needs of beginners and advanced learners alike, ensuring a customized learning path for everyone</li>
          <li>Cutting-Edge Techniques: Our curriculum incorporates the latest advancements in language teaching, making learning efficient and enjoyable</li>
          <li>Global Perspective: We prepare our students to thrive in a globalized world, emphasizing the importance of cross-cultural communication and understanding</li>
        </ul>
      </div>
    </section>

    <Statistics />

    <FeaturedCourse />

  

    <section className="home-courses">
      <h2>Our Main Courses</h2>
      <div className="course-grid">
        <div className="course-item">
          <Link to="/courses/german-language">
            <img src={image6} alt="German Language Course" loading="lazy" />
            <h3>German Language</h3>
            <p>Master the German language with our comprehensive courses.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/spoken-english">
            <img src={image4} alt="Spoken English Course" loading="lazy" />
            <h3>Spoken English</h3>
            <p>Enhance your spoken English skills with expert guidance.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/tuition">
            <img src={image5} alt="Tuition Course" loading="lazy" />
            <h3>Tuition</h3>
            <p>Receive personalized tuition to excel in your studies.</p>
          </Link>
        </div>
      </div>
    </section>

   
    <section className="student-carousel" style={{ backgroundImage: `url(${staticBackground})` }}>
      <h2>Student Experiences</h2>
      <Carousel>
        <Carousel.Item>
          <img className="student-photo" src={image7} alt="Student Experience 1" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant's Spoken English class boosted my communication skills with engaging lessons and expert guidance.</p>
            <p>Antony</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="student-photo" src={image9} alt="Student Experience 2" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant Academy's IELTS course was a game-changer. Structured lessons and dedicated instructors ensured my success. </p>
            <p>Davidoss</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="student-photo" src={image8} alt="Student Experience 3" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant Academy transformed my language skills. Made learning German enjoyable and effective</p>
            <p>Amritha</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
    <section className="location">
      <h2>Our Location</h2>
      <div className="map-container">
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1875506386427!2d76.2984495747936!3d10.001361490104102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa341573b5c27b26d%3A0xe338ac5bd8f2db4a!2sElegant%20Academy%20Kochi!5e0!3m2!1sen!2sin!4v1722487015216!5m2!1sen!2sin"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </section>

    <div className="hero-content">
      <h2>Join Us</h2>
      <p>Embark on a journey of linguistic and cultural discovery with Elegant Academy. Experience the joy of learning in an environment that is as enriching as it is educational. Your path to language mastery starts here!</p>
    </div>

    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Elegant Academy. All rights reserved.</p>
        <a href="https://www.freepik.com">Designed by Freepik</a>

        <div className="footer-icons">
          <a href="https://www.instagram.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="mailto:elegantgerman1@gmail.com">
            <i className="fas fa-envelope"></i>
          </a>
          <a href="https://www.facebook.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
        </div>
      </div>
    </footer>
  </div>
);

export default Home;

