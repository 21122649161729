// src/components/WhatsAppButton.js
import React from 'react';
import './WhatsAppButton.css'; // Import the CSS file for styling

function WhatsAppButton() {
  const phoneNumber = "9539411188"; // Replace with your actual phone number

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp"></i> {/* Font Awesome WhatsApp icon */}
    </a>
  );
}

export default WhatsAppButton;

