// src/components/Statistics.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faTrophy, faChalkboardTeacher, faBook } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import './Statistics.css';

function Statistics() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger every time the component is in view
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
    <div className="statistics" ref={ref}>
      <div className="stat-item">
        <div className="stat-icon">
          <FontAwesomeIcon icon={faUserGraduate} />
        </div>
        <div className="stat-number">
          {inView && <CountUp end={2500} duration={2.5} />}
        </div>
        <div className="stat-label">Students Enrolled</div>
      </div>
      <div className="stat-item">
        <div className="stat-icon">
          <FontAwesomeIcon icon={faTrophy} />
        </div>
        <div className="stat-number">
          {inView && <CountUp end={912} duration={2.5} />}
        </div>
        <div className="stat-label">Best Awards Won</div>
      </div>
      <div className="stat-item">
        <div className="stat-icon">
          <FontAwesomeIcon icon={faChalkboardTeacher} />
        </div>
        <div className="stat-number">
          {inView && <CountUp end={370} duration={2.5} />}
        </div>
        <div className="stat-label">Classes Completed</div>
      </div>
      <div className="stat-item">
        <div className="stat-icon">
          <FontAwesomeIcon icon={faBook} />
        </div>
        <div className="stat-number">
          {inView && <CountUp end={648} duration={2.5} />}
        </div>
        <div className="stat-label">Our Total Courses</div>
      </div>
    </div>
  );
}

export default Statistics;
