import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Home.css'; // Assuming you have common styles in Home.css
import './Courses.css';
import image1 from '../components/spoken.avif';
import image3 from '../components/learngerman_1.avif';
import image4 from '../components/tution_1.avif';
import image5 from '../components/abacus.avif';
import image6 from '../components/ielts_1.avif';
import image7 from '../components/antonyss.png';
import image8 from '../components/amrithass.png';
import image9 from '../components/davidossss.png';
import staticBackground from '../components/dbback.avif'

function Courses() {
  return (
    <div className="courses">
      <Helmet>
        <title>Our Courses - Elegant Academy</title>
        <meta name="description" content="Explore the diverse range of courses offered by Elegant Academy including German Language, Spoken English, IELTS, Tuition, and SMA Abacus." />
        <meta property="og:title" content="Our Courses - Elegant Academy" />
        <meta property="og:description" content="Explore the diverse range of courses offered by Elegant Academy including German Language, Spoken English, IELTS, Tuition, and SMA Abacus." />
        <meta property="og:image" content={image1} />
        <meta property="og:url" content="https://www.yourwebsite.com/courses" />
      </Helmet>
      <h1>Our Courses</h1>
      <div className="course-grid">
        <div className="course-item">
          <Link to="/courses/german-language">
            <img src={image3} alt="German Language" />
            <h3><strong>GERMAN LANGUAGE</strong></h3>
            <p>Learn the German language from beginner to advanced levels.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/spoken-english">
            <img src={image1} alt="Spoken English" />
            <h3><strong>SPOKEN ENGLISH</strong></h3>
            <p>Improve your spoken English skills for better communication.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/ielts">
            <img src={image6} alt="IELTS" />
            <h3><strong>IELTS</strong></h3>
            <p>Prepare for the IELTS exam with our comprehensive course.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/tuition">
            <img src={image4} alt="Tuition" />
            <h3><strong>TUITION</strong></h3>
            <p>Get personalized tuition for various subjects.</p>
          </Link>
        </div>
        <div className="course-item">
          <Link to="/courses/sma-abacus">
            <img src={image5} alt="SMA Abacus" />
            <h3><strong>SMA ABACUS</strong></h3>
            <p>Enhance your mental arithmetic skills with SMA Abacus.</p>
          </Link>
        </div>
      </div>
      <section className="student-carousel" style={{ backgroundImage: `url(${staticBackground})` }}>
      <h2>Student Experiences</h2>
      <Carousel>
        <Carousel.Item>
          <img className="student-photo" src={image7} alt="Student Experience 1" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant's Spoken English class boosted my communication skills with engaging lessons and expert guidance.</p>
            <p>Antony</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="student-photo" src={image9} alt="Student Experience 2" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant Academy's IELTS course was a game-changer. Structured lessons and dedicated instructors ensured my success. </p>
            <p>Davidoss</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="student-photo" src={image8} alt="Student Experience 3" loading="lazy" />
          <Carousel.Caption>
            <p>Elegant Academy transformed my language skills. Made learning German enjoyable and effective</p>
            <p>Amritha</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
      <section className="location">
        <div className="map-container">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.1875506386427!2d76.2984495747936!3d10.001361490104102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa341573b5c27b26d%3A0xe338ac5bd8f2db4a!2sElegant%20Academy%20Kochi!5e0!3m2!1sen!2sin!4v1722487015216!5m2!1sen!2sin"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </section>
      <footer className="footer">
        <p>&copy; 2024 Elegant Academy. All rights reserved.</p>
        <a href="https://www.freepik.com">Designed by Freepik</a>

        <a href="https://www.instagram.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="mailto:elegantgerman1@gmail.com">
          <i className="fas fa-envelope"></i>
        </a>
        <a href="https://www.facebook.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
      </footer>
    </div>
  );
}

export default Courses;
