import React from 'react';
import { Helmet } from 'react-helmet';
import infoimage1 from '../components/3.avif';
import infoimage2 from '../components/4.avif';
import infoimage3 from '../components/1.avif';
import './Info.css';

function Info() {
  return (
    <div className="info">
      <Helmet>
        <title>About Us - Elegant Academy</title>
        <meta name="description" content="Learn more about Elegant Academy, our vision, mission, and values. Discover our commitment to providing top-notch language education and empowering students to achieve their goals." />
        <meta name="keywords" content="Elegant Academy, language education, language academy, language courses, about us" />
        <meta property="og:title" content="About Us - Elegant Academy" />
        <meta property="og:description" content="Learn more about Elegant Academy, our vision, mission, and values. Discover our commitment to providing top-notch language education and empowering students to achieve their goals." />
        <meta property="og:url" content="https://yourwebsite.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
        <meta name="twitter:title" content="About Us - Elegant Academy" />
        <meta name="twitter:description" content="Learn more about Elegant Academy, our vision, mission, and values. Discover our commitment to providing top-notch language education and empowering students to achieve their goals." />
        <meta name="twitter:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
      </Helmet>

      <h2>About Elegant Academy</h2>
      <p>Elegant Academy is a leading institution dedicated to providing top-notch language education. Our mission is to empower individuals with the skills and confidence to communicate effectively in a globalized world.</p>
      
      <section className="info-section">
        <h3>Our Vision</h3>
        <img src={infoimage1} alt="German Language" />
        <p>To be the premier language academy that fosters cultural enrichment and linguistic proficiency. We strive to create a dynamic and immersive learning experience that goes beyond traditional education.</p>
      </section>
      
      <section className="info-section">
        <h3>Our Mission</h3>
        <img src={infoimage2} alt="German Language" />
        <p>Our mission is to provide innovative language programs that integrate the latest advancements in language pedagogy. We aim to empower students to reach their full potential and achieve their personal and professional goals.</p>
        <img src={infoimage3} alt="German Language" />
      </section>
      
      <section className="info-section">
        <h3>Our Values</h3>
        <ul>
          <li>Excellence in education</li>
          <li>Commitment to student success</li>
          <li>Innovation and creativity</li>
          <li>Respect for diversity</li>
          <li>Community engagement</li>
        </ul>
      </section>
      
      <footer className="footer">
        <p>&copy; 2024 Elegant Academy. All rights reserved.</p>
        <a href="https://www.freepik.com">Designed by Freepik</a>
        
        <a href="https://www.instagram.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="mailto:elegantgerman1@gmail.com">
          <i className="fas fa-envelope"></i>
        </a>
        <a href="https://www.facebook.com/elegantacademykochi/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
      </footer>
    </div>
  );
}

export default Info;
