import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'; // Import Link component
import '../components/GermanLanguage.css'; // Import CSS for styling
import germanImage from '../components/learngerman_1.avif'; // Adjust the path to your image

function GermanLanguage() {
  return (
    <div className="course-details">
      <Helmet>
        <title>German Language Course - Elegant Academy</title>
        <meta name="description" content="Learn the German language from beginner to advanced levels with Elegant Academy's comprehensive courses. Gain confidence and fluency in speaking, listening, reading, and writing German." />
        <meta name="keywords" content="German language course, learn German, German classes, language learning, language course, Elegant Academy" />
        <meta property="og:title" content="German Language Course - Elegant Academy" />
        <meta property="og:description" content="Learn the German language from beginner to advanced levels with Elegant Academy's comprehensive courses. Gain confidence and fluency in speaking, listening, reading, and writing German." />
        <meta property="og:url" content="https://yourwebsite.com/courses/GermanLanguage" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
        <meta name="twitter:title" content="German Language Course - Elegant Academy" />
        <meta name="twitter:description" content="Learn the German language from beginner to advanced levels with Elegant Academy's comprehensive courses. Gain confidence and fluency in speaking, listening, reading, and writing German." />
        <meta name="twitter:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
      </Helmet>

      <img src={germanImage} alt="German Language" className="course-image" />
      <div className="course-content">
        <h2>German Language Course</h2>
        <p><strong>
        Learn the German language from beginner to advanced levels.
        Embark on a linguistic journey with our comprehensive German language courses. Whether you're a complete beginner or looking to refine your skills, our structured curriculum covers all aspects of the language. From mastering basic vocabulary and grammar to engaging in advanced conversations, our interactive classes ensure you gain confidence and fluency. Immerse yourself in German culture and enhance your language proficiency through real-world applications and cultural insights.</strong></p>
        <h3>Course Highlights:</h3>
        <ul>
          <li>Interactive lessons with experienced instructors</li>
          <li>Focus on speaking, listening, reading, and writing skills</li>
          <li>Flexible schedules to suit your availability</li>
          <li>Access to additional resources and practice materials</li>
          <li>Certificate of completion upon finishing the course</li>
        </ul>
        <h3>Course Duration:</h3>
        <p>
          <strong>A1:</strong> 30 Working Days <br />
          <strong>A2:</strong> 30 Working Days <br />
          <strong>B1:</strong> 45 Working Days <br />
          <strong>B2:</strong> 45 Working Days
        </p>
        <h3>ONLINE/OFFLINE</h3>
        <h3>Enroll Now!</h3>
        <p><strong>
          Ready to start your journey with the German language? Click the button below to enroll or contact us for more details.
          </strong> </p>
        {/* Use Link component for client-side navigation */}
        <Link to="/contact" className="btn btn-primary">Contact Us</Link>

        <ul className="navigation-links">
          <h3>Explore Other Courses:</h3>
          <li><Link to="/courses/Spoken-English">SPOKEN ENGLISH</Link></li>
          <li><Link to="/courses/IELTS">IELTS</Link></li>
          <li><Link to="/courses/SMA-Abacus">SMA ABACUS</Link></li>
          <li><Link to="/courses/Tuition">TUTION</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default GermanLanguage;
