import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../components/GermanLanguage.css'; // Import CSS for styling
import germanImage from '../components/tution_1.avif'; // Adjust the path to your image

function Tution() {
  return (
    <div className="course-details">
      <Helmet>
        <title>Personalized Tuition - Elegant Academy</title>
        <meta name="description" content="Achieve academic excellence with Elegant Academy's Personalized Tuition Course. Tailored lessons, flexible schedules, and individual progress tracking to meet your unique learning needs." />
        <meta name="keywords" content="personalized tuition, academic excellence, exam preparation, study techniques, online tuition, offline tuition, Elegant Academy" />
        <meta property="og:title" content="Personalized Tuition - Elegant Academy" />
        <meta property="og:description" content="Achieve academic excellence with Elegant Academy's Personalized Tuition Course. Tailored lessons, flexible schedules, and individual progress tracking to meet your unique learning needs." />
        <meta property="og:url" content="https://yourwebsite.com/courses/Tuition" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
        <meta name="twitter:title" content="Personalized Tuition - Elegant Academy" />
        <meta name="twitter:description" content="Achieve academic excellence with Elegant Academy's Personalized Tuition Course. Tailored lessons, flexible schedules, and individual progress tracking to meet your unique learning needs." />
        <meta name="twitter:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
      </Helmet>

      <img src={germanImage} alt="Tuition" className="course-image" />
      <div className="course-content">
        <h2>Personalized Tuition</h2>
        <p>
          <strong>
            Our Personalized Tuition Course is designed to cater to your unique learning needs, helping you achieve academic excellence quickly and efficiently. Whether you need support in specific subjects or are looking to enhance your overall performance, our course offers tailored training to meet your needs.
          </strong>
        </p>
        <h3>Course Highlights:</h3>
        <ul>
          <li>Customized lessons with experienced instructors</li>
          <li>Focus on core subjects, exam preparation, and study techniques</li>
          <li>Flexible schedules to suit your availability</li>
          <li>Individual progress tracking and feedback</li>
        </ul>
   
        <h3>Enroll Now!</h3>
        <p>
          <strong>
            Ready to start your personalized learning journey? Click the button below to enroll or contact us for more details.
          </strong>
        </p>
        <Link to="/contact" className="btn btn-primary">Contact Us</Link>
        <ul className="navigation-links">
          <h3>Explore Other Courses:</h3>
          <li><Link to="/courses/IELTS">IELTS</Link></li>
          <li><Link to="/courses/German-Language">GERMAN LANGUAGE</Link></li>
          <li><Link to="/courses/SMA-Abacus">SMA ABACUS</Link></li>
          <li><Link to="/courses/Spoken-English">SPOKEN ENGLISH</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Tution;
