import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './CoverPage.css';

function CoverPage() {
  return (
    <div className="cover-page">
      <Helmet>
        <title>Welcome to Elegant Academy - Master a New Language</title>
        <meta name="description" content="Elegant Academy offers expert language courses to help you master a new language. Join us today and start your journey!" />
        <meta name="keywords" content="Elegant Academy, language learning, language courses, learn German, learn English, IELTS, language mastery" />
        <meta name="author" content="Elegant Academy" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Welcome to Elegant Academy" />
        <meta property="og:description" content="Your journey to mastering a new language begins here. Explore our courses and start learning today!" />
        <meta property="og:image" content="C:\Users\JAMES RAYS 123\Desktop\react\elegant\src\components\hem.avif" />
        <meta property="og:url" content="URL_OF_THIS_PAGE" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome to Elegant Academy - Master a New Language" />
      </Helmet>
      <div className="overlay">
        <div className="cover-content">
          <h1 className="animate-fadeInUp">Welcome to Elegant Academy</h1>
          <p className="animate-fadeInUp-delay">Your journey to a successful career begins here.</p>
          <div className="cover-buttons animate-fadeInUp-delay" style={{ animationDelay: '1s' }}>
            <Link to="/home" className="btn btn-primary">Explore Now</Link>
            <Link to="/courses" className="btn btn-secondary">View Courses</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoverPage;
