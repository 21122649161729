import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../components/GermanLanguage.css'; // Import CSS for styling
import germanImage from '../components/ielts_1.avif'; // Adjust the path to your image

function IELTS() {
  return (
    <div className="course-details">
      <Helmet>
        <title>IELTS Course - Elegant Academy</title>
        <meta name="description" content="Achieve the highest possible score in the IELTS exam with Elegant Academy's comprehensive IELTS Mastery Course. Flexible schedules and experienced instructors." />
        <meta name="keywords" content="IELTS course, IELTS preparation, language learning, Elegant Academy" />
        <meta property="og:title" content="IELTS Mastery Course - Elegant Academy" />
        <meta property="og:description" content="Achieve the highest possible score in the IELTS exam with Elegant Academy's comprehensive IELTS Mastery Course. Flexible schedules and experienced instructors." />
        <meta property="og:url" content="https://yourwebsite.com/courses/IELTS" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
        <meta name="twitter:title" content="IELTS Mastery Course - Elegant Academy" />
        <meta name="twitter:description" content="Achieve the highest possible score in the IELTS exam with Elegant Academy's comprehensive IELTS Mastery Course. Flexible schedules and experienced instructors." />
        <meta name="twitter:image" content="https://yourwebsite.com/path/to/social-image.jpg" />
      </Helmet>

      <img src={germanImage} alt="IELTS Course" className="course-image" />
      <div className="course-content">
        <h2>IELTS  Course</h2>
        <p><strong>
          Our IELTS Mastery Course is designed to help you achieve the highest possible score in the IELTS exam quickly and efficiently. Whether you are a beginner or looking to enhance your existing skills, our course offers comprehensive training to meet your needs.
        </strong></p>
        <h3>Course Highlights:</h3>
        <ul>
          <li>Interactive lessons with experienced instructors</li>
          <li>Focus on speaking, listening, reading, and writing skills</li>
          <li>Flexible schedules to suit your availability</li>
          <li>Access to additional resources and practice materials</li>
          <li>Certificate of completion upon finishing the course</li>
        </ul>
        <h3>Course Details:</h3>
        <p>
          <strong>Duration:</strong> 12 weeks <br />
          <strong>Frequency:</strong> 3 classes per week <br />
          <strong>Mode:</strong> Online/Offline <br />
         
        </p>
        <h3>Enroll Now!</h3>
        <p><strong>
          Ready to start your journey with the IELTS? Click the button below to enroll or contact us for more details.
        </strong></p>
        <Link to="/contact" className="btn btn-primary">Contact Us</Link>

        <ul className="navigation-links">
          <h3>Explore Other Courses:</h3>
          <li><Link to="/courses/Spoken-English">SPOKEN ENGLISH</Link></li>
          <li><Link to="/courses/German-Language">GERMAN LANGUAGE</Link></li>
          <li><Link to="/courses/SMA-Abacus">SMA ABACUS</Link></li>
          <li><Link to="/courses/Tuition">TUTION</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default IELTS;
