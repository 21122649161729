import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../components/GermanLanguage.css'; // Import CSS for styling
import germanImage from '../components/spoken.avif'; // Adjust the path to your image

function SpokenEnglish() {
  return (
    <div className="course-details">
      <Helmet>
        <title>Spoken English Course - Improve Your Communication Skills</title>
        <meta name="description" content="Enhance your spoken English skills with our comprehensive course. Focus on pronunciation, vocabulary, and conversation with interactive activities and personalized feedback. Enroll now to speak English fluently and confidently." />
        <meta name="keywords" content="Spoken English course, improve English speaking skills, English pronunciation, conversational English, interactive English lessons" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/courses/Spoken-English" />
      </Helmet>
      <img src={germanImage} alt="Spoken English Course" className="course-image" />
      <div className="course-content">
        <h2>SPOKEN ENGLISH</h2>
        <h4>Improve your spoken English skills for better communication</h4>
        <p>
          <strong>
            Unlock the power of effective communication with our Spoken English course. Designed to enhance your speaking skills, this course focuses on improving pronunciation, vocabulary, and conversational abilities. Participate in interactive activities, group discussions, and practical exercises that simulate real-life situations. Our experienced instructors provide personalized feedback to help you speak English fluently and confidently in both personal and professional settings.
          </strong>
        </p>
        <h3>Course Highlights:</h3>
        <ul>
          <li>Interactive speaking exercises with real-life scenarios</li>
          <li>Personalized feedback to improve pronunciation and fluency</li>
          <li>Group discussions and role-playing activities</li>
          <li>Flexible class timings to fit your schedule</li>
          <li>Access to online resources and practice sessions</li>
        </ul>
        <h3>Course Details:</h3>
        <p>
          <strong>Duration:</strong> 12 weeks <br />
          <strong>Frequency:</strong> 3 classes per week <br />
          <strong>Mode:</strong> Online/Offline <br />
          
        </p>
        <h3>Enroll Now!</h3>
        <p>
          <strong>
            Enhance your spoken English skills with us. Click the button below to enroll or contact us for more details.
          </strong>
        </p>
        <Link to="/contact" className="btn btn-primary">Contact Us</Link>

        <ul className="navigation-links">
          <h3>Explore Other Courses:</h3>
          <li><Link to="/courses/IELTS">IELTS</Link></li>
          <li><Link to="/courses/German-Language">GERMAN LANGUAGE</Link></li>
          <li><Link to="/courses/SMA-Abacus">SMA ABACUS</Link></li>
          <li><Link to="/courses/Tuition">TUTION</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default SpokenEnglish;

