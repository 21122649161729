import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './FeaturedCourse.css';

function FeaturedCourse() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  return (
    <motion.section
      className="featured-course"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 1 }}
    >
      <h2>Featured Course</h2>
      <p>
        Discover our most sought-after course and embark on a journey towards a brighter future. Whether you're looking to enhance your career prospects or explore new opportunities, our comprehensive <strong>German language</strong> program is designed just for you.
      </p>
      <p>
        We offer instruction from <strong>A1</strong> to <strong>B2</strong> levels in both online and offline formats, ensuring flexibility and convenience to suit your learning style.
      </p>
      <Link to="/courses/german-language" className="btn btn-primary">Learn More</Link>
    </motion.section>
  );
}

export default FeaturedCourse;
