// src/components/CallUsButton.js
import React from 'react';
import './CallUsButton.css'; // Import the CSS file for styling

function CallUsButton() {
  const phoneNumber = "9539411188"; // Replace with your actual phone number

  return (
    <a
      href={`tel:${phoneNumber}`}
      className="call-us-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-phone"></i> {/* Font Awesome Phone icon */}
    </a>
  );
}

export default CallUsButton;
